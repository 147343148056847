import React from 'react';
import type { Item } from '../../types/Nav';

declare var submit_search;

interface Props {
  brands: Item[];
}

/**
 * A lot of this functionality is still held in legacy code.
 * This isn't desirable, but until we don't use the legacy search bar it does prevent duplicate logic.
 * 
 * Reference: legacy-frp/public_html/js/header.js
 */
export default function Search(props: Props) {
  const [touched, setTouched] = React.useState(false);
  const [value, setValue] = React.useState('MODEL #, PART #, CATALOG #, SEARCH TERM');
  const form = React.useRef();

  function handleFocus() {
    if (!touched) {
      setTouched(true);
      setValue('');
    }
  }

  function handleChange(e) {
    setValue(e.target.value);
  }

  function handleSubmit() {
    // Globally available from legacy-frp/public_html/js/common.js
    // Manipulates the form object before submitting
    submit_search(form.current);
  }

  const style = touched ? { color: '#000', textTransform: 'none' as const } : {};
  return (
    <form action="/part_search/PartNumberSearch/" method="post" name="top_search" onSubmit={handleSubmit} className="hidden-xs" ref={form}>
      <select name="brand_id" id="top_brand_id">
        <option value="0">All Brands</option>
        {props.brands.map(brand => (
          <option key={brand.value} value={brand.value}>{brand.label}</option>
        ))}
      </select>
      <input type="text" maxLength={255} autoComplete="off" style={style} value={value} onChange={handleChange} onFocus={handleFocus} name="keywords" id="id_keywords" />
      <input type="hidden" name="equipment_id" id="id_equipment_id" />
      <input type="submit" id="search_but" value="Search" />
      <div className="listWrap" id="top_search_wrap">
        <ul className="searchList" id="top_search_list"></ul>
      </div>
    </form>
  )
}