import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Sentry from '@sentry/react';
import { cartOperations } from '../../redux/cart';
import Body from '../../components/Body';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import WebsiteRequest from '../../components/Footer/WebsiteRequest';
import AdminChat from '../../components/Bottom/AdminChat';
import Analytics from '../../components/Analytics';
import { useAuth } from '@developers/single-sign-on';
import LogoutWarning from '../../components/LogoutWarning';
import AdminSideMenu from '../../components/AdminSideMenu';
import ErrorBoundaryMessage from '../../components/ErrorBoundaryMessage';
import AutoBanner from '../../components/AutoBanner';
import { config } from '../../utils/config';
import { hotjar } from 'react-hotjar';

/**
 * Allows us to incrementally convert the App component into a function
 * 
 */
const FunctionalWrapper = (props) => {
  const auth = useAuth();

  React.useEffect(() => {
    // Redirect if employee password is expired
    if (auth.session_data.user?.require_password_update) {
      window.location.href = config('file_host') + '/account.php?action=change_pw&msg=1';
    }
    //Redirect users to update their password if they used a password generated by OST
    if(auth.session_data.user?.used_generated_password) {
      window.location.href = config('file_host') + '/account.php?action=change_pw&msg=1';
    }

    if (auth.isAuthenticated) {
      Sentry.setUser({
        id: auth.session_data.user?.user_id,
        username: auth.session_data.user?.full_name,
        email: auth.session_data.user?.email,
      });
      if (config('hot_jar_id')) {
        hotjar.identify(auth.session_data.user?.user_id, {
          username: auth.session_data.user?.full_name,
          email: auth.session_data.user?.email,
        });
      }
    }
  
    props.getCartCount();
  }, [auth.isAuthenticated]);

  return <App {...props} auth={auth} />
}

function mapStateToProps(state) {
  return {
    cartCount: state.getIn(['cart', 'count']),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getCartCount: cartOperations.getCartCount,
    updateCartCount: cartOperations.updateCartCount,
  }, dispatch)
}

class App extends Component {
  constructor(props) {
    super(props);

    this.state = { 
      size: 'lg',
      headerHeight: 0,
      useAnalytics: true,
      slideoutComponents: {},
      hideWrapper: false,
      collapsedHeader: false,
    }; 
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('onresize', this.updateSize, false);
    }
    setInterval(this.checkScroll, 100);
  }

  updateSize = () => {
    const size = this.getSize();
    if (size !== this.state.size) {
      this.setState({ size });
    }
  }

  toggleWrapperHide = (show) => {
    if (show !== this.state.hideWrapper) {
      this.setState({ hideWrapper: show });
    }
  }

  checkScroll = () => {
      let scroll = window.pageYOffset;

      const currentHeight = this.getHeaderHeight();
      if (currentHeight !== this.state.headerHeight) {
        if (scroll >= 90 && this.state.collapsedHeader == false) {
         this.setState({collapsedHeader: true, headerHeight: currentHeight})
        }
        else if (scroll < 90 && this.state.collapsedHeader == true){
          this.setState({ collapsedHeader: false, headerHeight: currentHeight })
        }
        this.setState({ headerHeight: currentHeight });
      }

      if (scroll >= 90 && this.state.collapsedHeader == false) {
       this.setState({ collapsedHeader: true })
      }
      else if (scroll < 90 && this.state.collapsedHeader == true){
        this.setState({ collapsedHeader: false })
      }
  }

  /**
   * Get size of screen based on bootstrap breakpoints
   */
  getSize() {
    const width = window.innerWidth;

    // All sizes
    if (width >= 1200) {
      return 'lg';
    } else if (width >= 992) {
      return 'md';
    } else if (width >= 768) {
      return 'sm';
    } else if (width >= 475) {
      return 'xs';
    } else {
      return 'xxs';
    }
  }

  /**
   * Get the height of the header navigation section.
   */
  getHeaderHeight() {
    const topSection = document.getElementById('topSectionTop');
    const bottomSection = document.getElementById('topSectionBottom');
    if (topSection && bottomSection) {
      return topSection.offsetHeight + bottomSection.offsetHeight;
    }

    return 0;
  }

  addToSlideout = ({ key, component }) => {
    this.setState({ slideoutComponents: { ...this.state.slideoutComponents, [key]: component}});
  }

  removeFromSlideout = (key) => {
    let slideoutComponents = this.state.slideoutComponents;
    delete slideoutComponents[key];
    this.setState({ slideoutComponents });
  }

  render() {
    const { auth, cartCount } = this.props;
    const { collapsedHeader, headerHeight } = this.state;
    const showWrapper = true;

    return (
      <Analytics>
        <Header 
          collapsedHeader={collapsedHeader}
          show={showWrapper}
          headerHeight={headerHeight}
          cartCount={cartCount}
        />
        <Body full={true}>
          <Sentry.ErrorBoundary fallback={<ErrorBoundaryMessage />}>
            <AutoBanner />
            <div className="container-fluid" style={{ padding: 0 }}>
              <div style={{ position: 'initial', minHeight: 200 }}>
                {this.props.children}
              </div>
            </div>
          </Sentry.ErrorBoundary>
          <WebsiteRequest admin={auth.isAuthenticated && auth.session_data.admin} hide={false} />
          <Footer show={showWrapper} />
        </Body>
        <AdminChat admin={showWrapper && auth.isAuthenticated && auth.session_data.admin} />
        <AdminSideMenu show={showWrapper} />
        <LogoutWarning />
      </Analytics>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FunctionalWrapper);
