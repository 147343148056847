import { prefix, ajax } from './common';

export async function getBanners() {
  return await ajax({
    url: prefix + '/banners'
  });
}

export async function getActiveBanners(status = 'active') {
  return await ajax({
    url: prefix + '/plugins/banners',
    data: {
      status
    }
  });
}

export async function createBanner(bannerInfo) {
  return await ajax({
    method: 'POST',
    url: prefix + '/banners',
    data: {
      name: bannerInfo.name,
      message: bannerInfo.message,
      description: bannerInfo.description,
      status: bannerInfo.status,
      sites: bannerInfo.sites,
      end_date: bannerInfo.end_date
    }
  });
}

export async function getBanner(id) {
  return await ajax({
    url: prefix + '/banners/' + id
  });
}

export async function editBanner(bannerInfo) {
  return await ajax({
    method: 'PUT',
    url: prefix + '/banners/' + bannerInfo.id,
    data: {
      name: bannerInfo.name,
      message: bannerInfo.message,
      description: bannerInfo.description,
      status: bannerInfo.status,
      sites: bannerInfo.sites,
      end_date: bannerInfo.end_date
    }
  });
}

export async function deleteBanner(bannerInfo) {
  return await ajax({
    method: 'DELETE',
    url: prefix + '/banners/' + bannerInfo.id,
    data: {
      name: bannerInfo.name,
      message: bannerInfo.message,
      description: bannerInfo.description,
      status: bannerInfo.status,
      sites: bannerInfo.sites,
      end_date: bannerInfo.end_date
    }
  });
}